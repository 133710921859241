import {racingHttp} from '@utils';

export interface PrizeItem {
  id: number;
  name: string;
}

export interface CartListItem extends MixBetOpt {
  type: string;
  modeID: number;
  selectPrize: number;
  amount: number;
  betItem?: number;
}

export interface LotteryItem {
  /** 彩票ID */
  lotteryID: number;
  /** 彩票名称 */
  lotteryName: string;
  /** 剩余开奖秒数(-1表示没有正在进行的期) */
  lessSec: number;
  /** 时间渲染dom,前端需要的属性 */
  renderTimer?: React.ReactNode;
  /** 是否关闭,前端需要的属性 */
  disabled?: boolean;
}

export enum LotteryMode {
  'Two Side' = 1,
  'TwoSide' = LotteryMode['Two Side'],
  '4D' = 2,
  '3D',
  '2D',
  '4X',
  '3X',
  '2X',
  '1 Digit',
  '1Digit' = LotteryMode['1 Digit'],
  FishPrawnCrab = 9,
}
export interface MixBetOpt {
  label: string;
  value: string;
  odds?: number;
  min?: number;
  max?: number;
}

export type LotteryModeName = keyof typeof LotteryMode;

export const typeKeyMap = {
  Big: '5,6,7,8,9',
  Small: '0,1,2,3,4',
  Odd: '1,3,5,7,9',
  Even: '0,2,4,6,8',
  Fish: '1,2,3',
  Prawn: '4,5,6',
  Crab: '7,8,9',
};

export interface LotteryModeItem {
  lotteryID: number;
  lotteryName: string;
  lessSec: number;
  icon: string;
  isQuick?: boolean;
}

export interface LotteryItemInfo {
  lotteryName: string;
  icon: string;
  /** 期号 */
  roundNo: string;
  /** 剩余开奖秒数(-1表示没有正在进行的期) */
  lessSec: number;
  /** 上一期期号 */
  lastRoundNo: string;
  stopSec?: number;
  /** 默认选择模式的模式ID */
  selectMode: LotteryMode;
  modeInfos: LotteryModeData[];
  /** 历史100条开奖结果 */
  analyzeData: string[][];
  /** 已下注历史 */
  roundTicketHis: roundTicketHisType[];
  tabs?: TabItem[];
  isLive?: boolean;
}
export interface TabItem {
  lotteryID: number;
  tabName: number;
  lotteryName: string;
}

export interface roundTicketHisType {
  betItem: number;
  betNo: string;
  modeID: LotteryMode;
  tabID: number;
}

export type LotteryModeData = {
  modeName: LotteryModeName;
  tabs: ModeDataTabItem[];
  /** 前端用于保留上一次选中状态的属性 */
  checkValue?: string[][];
  /** 单次下单允许的最大数量 */
  maxTicketCount?: number;
  modeID: LotteryMode;
  /** modeid是1和9的时候是数组,其他是对象 */
  itemData: ModeDataItem | ModeDataItem[];
} & ({} | {});

export interface ModeDataTabItem {
  /** 后端返回和接受的是number,经过tab后被转为了string */
  id: number;
  name: string;
}

export interface ModeDataItem {
  /** 注点ID 1~12对应ABCD单双大小 */
  itemID?: number;
  /** 赔率 */
  odds: number;
  /** 最大下注金额 */
  max: number;
  /** 最小下注金额 */
  min: number;
  /** 默认下注金额 */
  def: number;
}

export interface LotteryResultHistory {
  totalCount: number;
  rounds: LotteryResultHistoryItem[];
  tabs?: LotteryHistoryTabItem[];
}
export interface LotteryResultHistoryItem {
  roundNo: string;
  results: string[];
  drawTime: number;
}
export interface LotteryHistoryTabItem {
  lotteryID: number;
  lotteryName: string;
  tabName: string;
}

export interface LotteryOrders {
  totalCount: number;
  records: LotteryOrder[];
}
export interface LotteryOrder {
  createTime: number;
  drawSec: number;
  lotteryName: string;
  lotteryID: number;
  orderGroup: string;
  roundNo: string;
  shareGameDto: {
    gameCode: string;
  };
  wonCode: string[];
  /** 0未开奖 1中奖 2未中奖 */
  status: 0 | 1 | 2;
  totalAmount: number;
  totalPrize: number;
  ticketsLists: LotteryOrderTicket[];
  icon: string;
}

export interface LotteryOrderTicket {
  modeID: LotteryMode;
  modeName: LotteryModeName;
  tabID: number;
  tabName: string;
  amount: number;
  fee: number;
  prize: number;
  betItem: number;
  betNo: string;
  odds: number;
  ticketStatus: number;
}

export type LotteryTicketItem = {
  modeID: LotteryMode;
  tabID: number;
  amount: number;
  /** 购买号码(当模式为4d,4x,3d,3x,2d,2x,1digit需传入) */
  betNo?: number | number[];
  /** 购买注点(当模式为twoside,F/P/C需传入),根据顺序下标对应 */
  betItem?: number;
  /** 以下属性均为前端购物车显示需要 */
  odds?: number;
  betValue?: string | string[];
  tabName?: string;
  color?: string | string[];
  max?: number;
  min?: number;
  groupIndex?: number;
};

export interface BettingReq {
  lotteryID: number;
  roundNo: string;
  tickets: LotteryTicketItem[];
}

const isSingle = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['1 Digit']:
    case LotteryMode['Two Side']:
    case LotteryMode.FishPrawnCrab:
      return true;
  }
  return false;
};

export const isNumber = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode.FishPrawnCrab:
    case LotteryMode['Two Side']:
      return false;
  }
  return true;
};

const isx4 = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['4D']:
    case LotteryMode['4X']:
      return true;
  }
  return false;
};
const isx3 = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['3D']:
    case LotteryMode['3X']:
      return true;
  }
  return false;
};
const isx2 = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['2D']:
    case LotteryMode['2X']:
      return true;
  }
  return false;
};
const isx1 = (mode: LotteryMode) => {
  return mode === LotteryMode['1 Digit'];
};
const isBSOD = (mode: LotteryMode) => {
  return mode === LotteryMode['Two Side'];
};
const isFPC = (mode: LotteryMode) => {
  return mode === LotteryMode.FishPrawnCrab;
};
const isD = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['4D']:
    case LotteryMode['3D']:
    case LotteryMode['2D']:
      return true;
  }
  return false;
};

const isX = (mode: LotteryMode) => {
  switch (mode) {
    case LotteryMode['4X']:
    case LotteryMode['3X']:
    case LotteryMode['2X']:
    case LotteryMode['1 Digit']:
      return true;
  }
  return false;
};

export const checkType = (
  mode: LotteryMode,
): {
  /** 是否是数字 */
  isNumber: boolean;
  /** 是否是单选(1d/大小单双/鱼虾蟹) */
  isSingle: boolean;
  /** 是否是4d或4x */
  isx4: boolean;
  /** 是否是3d或3x */
  isx3: boolean;
  /** 是否是2d或2x */
  isx2: boolean;
  isx1: boolean;
  isBSOD: boolean;
  isFPC: boolean;
  isD: boolean;
  isX: boolean;
} => {
  return {
    isNumber: isNumber(mode),
    isSingle: isSingle(mode),
    isx4: isx4(mode),
    isx3: isx3(mode),
    isx2: isx2(mode),
    isx1: isx1(mode),
    isBSOD: isBSOD(mode),
    isFPC: isFPC(mode),
    isD: isD(mode),
    isX: isX(mode),
  };
};

const getUrl = (url: string, isQuick: boolean = false) => {
  return `/api/${isQuick ? 'lqi/v1/lo' : 'loi/v1/lottery'}/${url}`;
};

export const getLottoList = (isQuick: boolean = false) => {
  return racingHttp.get<null, LotteryModeItem[]>(getUrl('list', isQuick));
};

export const getLottoInfo = (lotteryID: number, isQuick: boolean = false) => {
  return racingHttp.get<string, LotteryItemInfo>(
    getUrl('info?lotteryID=' + lotteryID, isQuick),
  );
};

export const lottoBetting = (data: BettingReq, isQuick = false) => {
  return racingHttp.post<BettingReq, string>(
    `/api/${isQuick ? 'lqi' : 'loi'}/v1/order/create`,
    data,
  );
};

export const getLottoResult = (
  lotteryID: number,
  isQuick = false,
  pageNo = 1,
  size = 10,
) => {
  return racingHttp.post<null, LotteryResultHistory>(
    getUrl('history', isQuick),
    {
      lotteryID,
      pageNo,
      size,
    },
  );
};

export const getOrders = (
  lotteryID: number,
  isQuick = false,
  pageNo = 1,
  size = 3,
) => {
  return racingHttp.post<null, LotteryOrders>(
    `/api/${isQuick ? 'lqi' : 'loi'}/v1/order/list`,
    {
      lotteryID,
      pageNo,
      size,
    },
  );
};

/** 这个接口只有快速且有token才调用 */
export const getPrize = (lotteryID: number, roundNo: string) => {
  return racingHttp.post<
    null,
    {
      totalBet: number;
      totalPrize: number;
    }
  >('/api/lqi/v1/round/prize', {
    lotteryID,
    roundNo,
  });
};
