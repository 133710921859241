import Spin from '@/components/basic/spin';
import theme from '@/style';
import {View, ScrollView} from 'react-native';
import LotteryHeader from './lottery-header/lottery-header';
import React, {useCallback} from 'react';
import NavHeader from './component/nav-heder';
import {useTranslation} from 'react-i18next';
import ShopCart, {ShopCarRef} from '@/components/business/games/cart/cart';
import LotteryBets from './lottery-bets/lottery-bets';
import LotteryTab from './lottery-tab';
import History from './lottery-history/lottery-history';
import LotteryAnalyze from './lottery-analyze/lottery-analyze';
import LotteryOrder from './lottery-order/lottery-order';
import LotteryCart from './lottery-cart/lottery-cart';
import RuleModal from '@/components/business/games/rule';
import LotteryRule from './lottery-rule';
import {
  CartListItem,
  LotteryItemInfo,
  LotteryMode,
  LotteryModeData,
  checkType,
  getLottoInfo,
  getPrize,
  lottoBetting,
  getOrders,
} from './mix-lottery-service';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {BasicObject, SafeAny} from '@/types';
import BetTab from './component/bet-tab';
import {DEFAULT_TAB} from './constant';
import {defaultDetial} from './mix-lotto.mock';
import Drawer, {DrawerRef} from '@basicComponents/game-drawer/game-drawer';
import LotteryPicker from './lottery-picker';
import globalStore from '@/services/global.state';
import {goTo, getUUID, transfor, handleToLive} from '@/utils';
import QuickLottery from './quick/quick-lottery';
import {checkIsShown, saveIssueNum} from '@/components/utils/gameWin';
import GoldWin from '@/components/business/games/gold-win';
import Video from '@/components/basic/video';
import {useConfirm} from '@/components/basic/modal/confirm.hooks';
import PaidShareModal from '@/components/business/paid-share-modal';
import {getShareAward} from '@/services/global.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';

const MixLottery = ({navigation: {setParams}}: {navigation: BasicObject}) => {
  const user = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(user).userId;
  const {i18n} = useTranslation();
  const {params = {}} = useRoute() as BasicObject;
  const {isQuick: quickType = '0'} = params;
  const [isQuick, setIsQuick] = React.useState(quickType);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [prizeId, setPrizeId] = React.useState(1);
  const [showRule, setShowRule] = React.useState(false);
  const currentLotteryDetail = React.useRef<LotteryModeData>();
  const [lotteryID, setLotteryId] = React.useState<number>(
    Number(params?.lotteryID),
  );
  const [refreshCode, setRefreshCode] = React.useState(0);
  const refreshCodeRef = React.useRef(0);
  const [mode, setMode] = React.useState<`${LotteryMode}`>(
    `${DEFAULT_TAB[0].modeID}`,
  );
  const modeID = React.useMemo<LotteryMode>(() => Number(mode), [mode]);
  // `${DefaultTab[0].modeID}`,
  const [lotteryInfo, setLotteryInfo] = React.useState<LotteryItemInfo>();
  const [lotteryDetail, setLotteryDetail] =
    React.useState<LotteryModeData>(defaultDetial);
  const [loading, setLoading] = React.useState(false);
  const [carList, setCarList] = React.useState<CartListItem[]>([]);
  const picker = React.useRef<DrawerRef>(null);
  const totalOrder = React.useRef(0);
  const [orderNum, setOrderNum] = React.useState(0);
  const [timeVersion, setTimeVersion] = React.useState(0);
  const ShopCartRef = React.useRef<ShopCarRef>();
  const [tabToTop, setTabToTop] = React.useState(0);
  const scrollRef = React.useRef<ScrollView>(null);
  const [cutdownTime, setCutdownTime] = React.useState(0);
  const {getState} = useNavigation();
  const {index: stateIndex, routes} = getState();
  const currentRouteName = routes[stateIndex].name;
  const [disabledAll, setDisabledAll] = React.useState(true);
  const [winPaused, setWinPaused] = React.useState(true);
  const [winAmount, setWinAmount] = React.useState(0);
  const [showWin, setShowWin] = React.useState(false);
  const endElement = React.useRef<View>(null);
  const [popVisible, setPopVisible] = React.useState<boolean>(false);
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});

  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
    i18n.t('label.ok'),
  );
  const {renderModal: renderLiveConfirmModal, show: confirmLiveShow} =
    useConfirm('Watch Live', 'Cancel');
  const getGameInfo = async () => {
    try {
      setLoading(true);
      const res = await getLottoInfo(lotteryID, isQuick === '1');
      if (res) {
        if (res.analyzeData?.length > 0) {
          res.analyzeData = res.analyzeData.map(v => v.map(_ => _.slice(-6)));
        }
        setLotteryInfo(res);
        setMode(`${res.selectMode}`);
        setTimeVersion(timeVersion + 1);
        if (isQuick === '1') {
          getOpenResult(res.lastRoundNo);
        } else {
          if (res.lessSec <= 0) {
            confirmShow(
              i18n.t('mix-lotto.label.gameClosed'),
              i18n.t('mix-lotto.tip.gameClosed'),
              () => {},
              () => {},
              true,
            );
            return;
          }
        }
        setDisabledAll(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOpenResult = async (issueNo: string) => {
    if (!globalStore.token) {
      return;
    }
    try {
      const res = await getPrize(lotteryID, issueNo);
      if (res && res.totalPrize) {
        // 中奖
        const isShown = await checkIsShown(
          'mixLottery',
          `${lotteryID}_${issueNo}`,
        );
        if (!isShown) {
          getOrders(lotteryID, isQuick, 1, 1).then(r => {
            setThreeOrderData({
              userId,
              data: transfor(
                r?.records[0],
                isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
                {
                  from: 'order',
                },
              ),
            });
          });
          setWinAmount(res.totalPrize);
          setShowWin(true);
          setWinPaused(false);
          globalStore.updateAmount.next({gameType: 'MixLottery'});
          saveIssueNum('mixLottery', `${lotteryID}_${issueNo}`);
        }
      }
    } finally {
    }
  };

  const handleUpdateAmount = useCallback(() => {
    if (globalStore.token) {
      globalStore.updateAmount.next({gameType: 'MixLottery'});
    }
  }, []);
  useFocusEffect(handleUpdateAmount);

  React.useEffect(() => {
    if (lotteryID) {
      getGameInfo();
      if (carList.length) {
        setCarList([]);
      }
      if (prizeId !== 1) {
        setPrizeId(1);
      }
      if (orderNum > 0) {
        totalOrder.current = 0;
        setOrderNum(totalOrder.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryID]);

  React.useEffect(() => {
    if (!lotteryInfo) {
      return;
    }
    // 经过tab后变成了字符串,所以这里需要强转为数字
    const detail = lotteryInfo?.modeInfos.find(v => v.modeID === modeID)!;
    setLotteryDetail(detail);
    currentLotteryDetail.current = detail;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryInfo, mode]);

  const tabs = React.useMemo(() => lotteryDetail?.tabs || [], [lotteryDetail]);

  const analyzeData = React.useMemo(
    () => lotteryInfo?.analyzeData || [],
    [lotteryInfo],
  );

  const price = React.useMemo(
    () => carList.reduce((a, b) => a + b.amount, 0),
    [carList],
  );

  React.useEffect(() => {
    if (tabIndex === 2 && orderNum) {
      totalOrder.current = 0;
      setOrderNum(totalOrder.current);
    } else {
      refreshCodeRef.current = 0;
      setRefreshCode(refreshCodeRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  const onConfirm = async () => {
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    try {
      ShopCartRef.current?.closeModal();
      setLoading(true);
      const lottoBet = {
        lotteryID,
        roundNo: lotteryInfo!.roundNo,
        tickets: carList.map(item => {
          const {isNumber: isnum} = checkType(item.modeID);
          if (isnum) {
            return {
              modeID: item.modeID,
              tabID: item.selectPrize,
              amount: item.amount,
              betNo: Number(item.value),
            };
          } else {
            return {
              modeID: item.modeID,
              tabID: item.selectPrize,
              amount: item.amount,
              // 因为下标从0开始,后端需要从1开始,所以+1
              betItem: item.betItem! + 1,
            };
          }
        }),
      };
      const res = await lottoBetting(lottoBet, isQuick === '1');
      if (res) {
        if (!globalStore.imIcon) {
          globalStore.globalSucessTotal(i18n.t('paidSuccess.label.subTitle'));
        }
        const user = localStorage.getItem('user') || '{}';
        const userId = JSON.parse(user).userId;
        const shareUserId = localStorage.getItem('shareUserId');
        const [imStr, imGameCode] = (
          localStorage.getItem('PlayNow') || '-'
        ).split('-');
        if (shareUserId && imStr === 'im' && imGameCode === 'MixLottery') {
          postReport({
            reportType: TReportType.BETS,
            gameName: lotteryInfo?.lotteryName,
            gameType: isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
            userId,
            issueNo: lottoBet?.roundNo,
            sourceType: TSourceType.IM,
            shareUserId,
          });
          localStorage.removeItem('PlayNow');
        }

        getShareAward(
          isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
        ).then(award => {
          setShareAward(Math.floor(award));
          setPopVisible(true);
          setThreeOrderData({
            userId,
            data: transfor(
              lottoBet,
              isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
              {
                rest: {
                  gameIconUrl: lotteryInfo?.icon,
                  gameName: lotteryInfo?.lotteryName,
                  issNo: lottoBet?.roundNo,
                  openTime: Date.now() + (lotteryInfo?.lessSec || 0) * 1000,
                  totalAmount: lottoBet.tickets.reduce((r, cur) => {
                    r += cur.amount;
                    return r;
                  }, 0),
                },
              },
            ),
          });
        });
        if (tabIndex !== 2) {
          totalOrder.current += 1;
          setOrderNum(totalOrder.current);
        }
        setCarList([]);
        getGameInfo();
        globalStore.updateAmount.next({gameType: ''});
        refreshCodeRef.current += 1;
        setRefreshCode(refreshCodeRef.current);
      }
    } finally {
      setLoading(false);
    }
  };

  const totalSec = React.useMemo(() => {
    if (isQuick === '1') {
      if (lotteryInfo?.tabs) {
        const res = lotteryInfo.tabs.find(item => item.lotteryID === lotteryID);
        return res ? res.tabName * 60 : 0;
      }
    }
    return 0;
  }, [isQuick, lotteryID, lotteryInfo]);

  return (
    <>
      <Spin
        style={[theme.flex.flex1, theme.background.lightGrey]}
        loading={loading}>
        <NavHeader
          selectName={lotteryInfo?.lotteryName}
          onChange={() => picker.current?.open()}
        />
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <ScrollView ref={scrollRef} stickyHeaderIndices={[1]}>
            <View onLayout={e => setTabToTop(e.nativeEvent.layout.height)}>
              {isQuick === '1' && lotteryInfo && (
                <QuickLottery
                  tabs={lotteryInfo?.tabs}
                  lotteryID={lotteryID}
                  value={cutdownTime}
                  total={totalSec}
                  onTimeChange={v => {
                    if (v) {
                      setLotteryId(v);
                    }
                  }}
                />
              )}
              <LotteryHeader
                onDisabled={() => {
                  setDisabledAll(true);
                  ShopCartRef.current?.closeModal();
                  if (lotteryInfo?.isLive) {
                    confirmLiveShow(
                      'Gentle Reminder',
                      'Hi, Results of the current Draw have been announced! Please head over to our YouTube channel to watch live results, you might be the next lucky winner!',
                      handleToLive,
                    );
                  }
                }}
                onTimeEnd={() => {
                  if (isQuick === '1') {
                    getGameInfo();
                    refreshCodeRef.current += 1;
                    setRefreshCode(refreshCodeRef.current);
                  } else {
                    confirmShow(
                      i18n.t('mix-lotto.label.gameClosed'),
                      i18n.t('mix-lotto.tip.gameClosed'),
                      () => {},
                      () => {},
                      true,
                    );
                    setDisabledAll(true);
                  }
                }}
                isLive={lotteryInfo?.isLive}
                stopBetSec={
                  isQuick === '1'
                    ? lotteryInfo?.stopSec || 10
                    : lotteryInfo?.stopSec || 0
                }
                onTimeChange={setCutdownTime}
                isSame={currentRouteName === 'MixLottery'}
                hasCutdown={isQuick === '1'}
                goAnalyze={() => {
                  if (tabIndex !== 1) {
                    setTabIndex(1);
                  }
                  setTimeout(() => {
                    scrollRef?.current?.scrollTo({y: tabToTop});
                  }, 0);
                }}
                isQuick={isQuick === '1'}
                cycle={totalSec / 60}
                lotteryInfo={lotteryInfo}
                modeId={mode}
                timeVersion={timeVersion}
                showRule={() => setShowRule(true)}
              />
              <BetTab
                onChange={setMode}
                selectedKey={mode}
                tabList={(lotteryInfo?.modeInfos || DEFAULT_TAB).map(v => ({
                  label: LotteryMode[v.modeID],
                  key: `${v.modeID}`,
                }))}
              />

              <LotteryBets
                endElement={endElement.current}
                disabledAll={disabledAll}
                hasSelected={lotteryInfo?.roundTicketHis || []}
                lotteryDetail={lotteryDetail}
                selectPrize={prizeId}
                onChangePrize={setPrizeId}
                cartList={carList}
                lotteryID={lotteryID}
                onCartChange={v => {
                  setCarList([...v]);
                }}
              />
            </View>
            <LotteryTab
              index={tabIndex}
              setIndex={setTabIndex}
              orderNum={orderNum}
            />
            {tabIndex === 0 && (
              <History isQuick={isQuick === '1'} lotteryID={lotteryID} />
            )}
            {tabIndex === 1 && (
              <LotteryAnalyze analyze={analyzeData} tabs={tabs} />
            )}
            {tabIndex === 2 && (
              <LotteryOrder
                isQuick={isQuick === '1'}
                lotteryID={lotteryID}
                refreshCode={refreshCode}
              />
            )}
          </ScrollView>
        </View>
        <ShopCart
          endRef={endElement}
          ref={ShopCartRef}
          disable={carList.length === 0 || disabledAll}
          tip={`${carList.length} ${i18n.t('game-page.label.numbers')}`}
          price={price}
          onConfirm={onConfirm}
          onClear={() => {
            setCarList([]);
          }}
          title={`Bet Slip (${carList.length})`}>
          <LotteryCart
            carList={carList}
            changeAmount={(v, i) => {
              carList[i].amount = Number(v);
              setCarList([...carList]);
            }}
            onDelItem={i => {
              carList.splice(i, 1);
              setCarList([...carList]);
            }}
          />
        </ShopCart>

        <RuleModal
          visible={showRule}
          title="Rule"
          onClose={() => setShowRule(false)}>
          <LotteryRule modeInfos={lotteryInfo?.modeInfos} />
        </RuleModal>
        <Drawer
          ref={picker}
          mode="bottom"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}>
          <LotteryPicker
            isQuick={isQuick === '1'}
            onClose={() => picker.current?.close()}
            lotteryID={lotteryID}
            onItemSelect={(id, quick) => {
              setLotteryId(id);
              setIsQuick(quick || '0');
              const obj = {
                isQuick: quick,
                lotteryID: id,
              };
              setParams(obj);
              picker.current?.close();
            }}
          />
        </Drawer>
        <GoldWin
          amount={winAmount}
          shareAward={shareAward}
          visible={showWin}
          onClose={() => {
            setShowWin(false);
          }}
          sharInfo={{
            gameCode: isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
            orderNo: getUUID(),
            threeOrderData,
          }}
        />
        <Video
          onEnd={() => {
            setWinPaused(true);
          }}
          audioOnly
          source={require('@components/assets/music/win.mp3')}
          src={require('@components/assets/music/win.mp3')}
          paused={winPaused}
        />
        {renderConfirmModal}
        {renderLiveConfirmModal}
      </Spin>
      <PaidShareModal
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        shareAward={shareAward}
        sharInfo={{
          gameCode: isQuick === '1' ? 'quickStateLottery' : 'stateLottery',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
    </>
  );
};

export default MixLottery;
